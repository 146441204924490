import React, { Component } from 'react';
import * as Common from "../Common";
import $ from "jquery";
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
require("../style.css");

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username:"form-control",
            password:"form-control"
        };
        this.submit = this.submit.bind(this)
    }

      componentDidMount() {
    }

    submit(){
       // const that = this;
       const cookies = new Cookies();
    Common.ShowPageLoader();
    var username = document.getElementById("username").value;
    var password = document.getElementById("password").value;
    if(username !==''){

    }else{
        this.setState({username: "form-control red"});
        Common.HidePageLoader();
        return false;
    }
    if(password !==''){

    }else{
        this.setState({password: "form-control red"});
        Common.HidePageLoader();
        return false;
    }
    if(username == "spencer" && password =="8stlaac#"){
        // Expires Example
        var today = new Date();
        today.setDate(today.getDate()+ parseInt(2));
        cookies.set('admin', true, { expires: today });
        this.props.history.push("/orders");
    }else{
        Common.HidePageLoader();
        document.getElementById("error").innerHTML = "<p>Please enter correct username/password</p>";
    }
    }
    render() {
        return (
            <div className="App">
             <div id="a">
                <div className="payment">
                <h1 className="text-center">Login </h1>
                <div id="error" className="error text-center"></div>
                <div className="user-login">
                <div className="quote-form">
                        <input
                            type="text"
                            id="username"
                            className={this.state.username}
                            placeholder="Username"
                            pattern="[A-Za-z]+"
                            />
                            <input
                            type="password"
                            id="password"
                            className={this.state.password}
                            placeholder="Password"
                            />
                             </div>
                            </div>
                    <div className="pending-inner">
                    <div className="pending-payment">
                    <button onClick={this.submit}>Login</button>
                    </div>
                    <Link to="/thanks/a" id="thanks" className="leftmenu"/>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
