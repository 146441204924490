import React, {Component} from "react";
import $ from "jquery";
import {Link} from "react-router-dom";
import {reactLocalStorage} from "reactjs-localstorage";
import StripeCheckout from "react-stripe-checkout";
import * as Common from "../Common";
import moment from "moment";

let tempData;
export default class Reserve extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TotalBeforeAddons: 0,
      TotalAfterAddons: 0,
      TotalAfterAddmiles:0,
      rates: [],
      form: [],
      dates:[],
      SelectedAddOns: [],
      AddOns: [],
      paymentMethod: "",
      policy: "",
      pay: 0,
      additionalMiles:0,
      TotalMiles:0,
      totalprice: 0,
      addOnPrice: 0,
      addMilesPrice: 0,
      newMilesPrice:0,
      addOnData: [],
      paymenttype:'',
      return: true
    };
    this.AddonQuantityAdded = this
      .AddonQuantityAdded
      .bind(this);

    this.AddOnSelected = this
      .AddOnSelected
      .bind(this);

    this.AddMiles = this
      .AddMiles
      .bind(this);
  }

  GetAddonProducts(callback) {
    $.get("https://majestic-be.nextpageit.net/_/items/addonproducts?access_token=IrWq1BZwm3&" +
        "fields=*.*",
    data => {
      callback(data.data);
    })

  }

  componentDidMount() {
    
    if(reactLocalStorage.get("home") == 1){
      this.props.history.push("/");
    }
    let tempData = reactLocalStorage.getObject("rateData");
    let form = reactLocalStorage.getObject("formData");
    let dates = reactLocalStorage.getObject("form");

    this.setState({TotalMiles : 100 * tempData.NumberOfNights, rates: tempData,dates : dates, TotalBeforeAddons: tempData.Total, form: form, totalprice: tempData.Total});
    $("#addon-data").hide();
    $("#additional-miles").hide();
    
    $("#strip").hide();
  //  this.saveData();
    reactLocalStorage.set("totalPay", "");
    setTimeout(() => {
      this.UpdateTotals()
    }, 500);
    this.GetAddonProducts((data => {
      console.log("addon data",data);
      if (typeof data != "undefined") 
        this.setState({AddOns: data});
      }
    ));
    // const mouseOnlyNumberInputField = document.getElementById("miles");
    // mouseOnlyNumberInputField.addEventListener("keypress", (event) => {
    //   event.preventDefault();
    // });
  }
  AddMiles() {
    const _this = this;
    var miles = document
      .getElementById("miles")
      .value
      .trim();

    if (isNaN(miles) || (miles == "")) {
      miles = "0";
    }

    miles = parseInt(miles);

    var newmiles = parseInt(miles) + 100;
    newmiles = this.state.rates.NumberOfNights * newmiles;
    var milesPrice = newmiles * 0.35;
    var total = this.state.rates.BaseRate + milesPrice;
    var CostPerNight = total / this.state.rates.NumberOfNights;
    var randomNumber = Math.floor(Math.random() * 21);
    var finalTotal = total - (total * (randomNumber / 1000));
    var NewFinalTotal = Math.round(finalTotal + _this.state.addOnPrice);
 //   var costOfMiles = Math.round(milesPrice);
    //var CostPerNight = Math.round(CostPerNight);
    //var miles = newmiles;
    if(miles == 0 ){
      NewFinalTotal = this.state.TotalBeforeAddons;
     
    }
   var newmileprice =  parseInt(NewFinalTotal) - parseInt(this.state.TotalBeforeAddons);
    _this.setState({TotalMiles:newmiles ,TotalAfterAddmiles: NewFinalTotal ,addMilesPrice :newmileprice, additionalMiles : miles});
    if(miles > 0){
      $("#basic-total").hide();
      $("#additional-miles").show();
    }else{
      if(_this.state.TotalAfterAddons > 0 ){
        $("#basic-total").hide();
        $("#additional-miles").hide();
        $("#addon-data").show();
      }else{
        $("#basic-total").show();
        $("#additional-miles").hide();
        $("#addon-data").hide();
      }
      
    }

    
    
    setTimeout(() => {
      _this.UpdateTotals();
    }, 100);
  };
  AddOnSelected() {
    const _this = this;
    var checkedValues = $("input.addonProduct:checked");
    var selectedAddOns = [];
    checkedValues.each((idx, ctrl) => {
      var c = $(ctrl);
      var pricetype = parseInt(c.attr("data-price-type"));
      var rate = parseInt(c.attr("rate"));
      var data = {
        rate: pricetype == 1 ? rate * this.state.rates.NumberOfNights : rate,
        id: parseInt(c.attr("data-id")),
        name: c.attr("data-name")
      };
      selectedAddOns.push(data);
    })

    this.setState({SelectedAddOns: selectedAddOns});
    if (selectedAddOns.length > 0) {

      $("#basic-total").hide();
      $("#add-miles-total").hide();
      $("#addon-data").show();

      setTimeout(function () {
        //SET QUANTITY TO 1
        // selectedAddOns.map((item) => {
        //   var ctrl = $("#quan-" + item.id);
        //   return ctrl.val(1);
        // });
        setTimeout(() => {
          _this.UpdateTotals();
        }, 100);
      }, 100);

    } else {
     console.log("this.state.TotalAfterAddmiles",this.state.TotalAfterAddmiles);
      if(_this.state.TotalAfterAddmiles > 0 && _this.state.TotalAfterAddmiles != _this.state.TotalBeforeAddons){ 
      $("#basic-total").hide();
      $("#add-miles-total").show();
     }else{
        $("#basic-total").show();
        $("#add-miles-total").hide();
     } 
      $("#addon-data").hide();
      setTimeout(() => {
        _this.UpdateTotals();
      }, 500);
    }
  };
  submit = () => {
    const _this = this;
    var status = document
      .getElementById("privacy-policy")
      .checked;
    var payment = document.querySelector('input[name="diposit"]:checked');
    if (payment !== null) {
      var payableAmount = document
        .querySelector('input[name="diposit"]:checked')
        .value;
        //_this.setState({pay: payableAmount});
      reactLocalStorage.set("totalPay", payableAmount);
    } else {
      $(".full").addClass("adv");
      return false;
    }
    if (!status) {
      $(".privacy-policy").addClass("adv");
      return false;
    }
    $(".StripeCheckout").click();
    Common.ShowPageLoader();
  };
  onToken = token => {
    const _this = this;
    var totalPrice = this.state.TotalBeforeAddons;
    if(this.state.TotalAfterAddons > 0) 
      totalPrice = this.state.TotalAfterAddons;
    var info = {
      stripeToken: token.id,
      amount: this.state.pay,
      mode: "sandbox",
      description: "Wix"
    };
    var settings = {
      async: false,
      crossDomain: true,
      url: "https://orderformv1.nextpageit.net/Stripe/test.php",
      method: "POST",
      headers: {},
      data: JSON.stringify(info),
      beforeSend:function () {
        Common.ShowPageLoader();
      }
    };
      $.ajax(settings).done(function (response) {
        Common.ShowPageLoader();
        console.log(response, "<==respones");
        let success = JSON.parse(response);
        _this.saveData(success);
        _this.sendEmail(success);
      });
  };
  AddonQuantityAdded(e) {
    this.UpdateTotals();
  };
  UpdateTotals() {
    var ctrlBeforeAddons = $("#total-before-addons");
    var ctrlAfterAddons = $("#total-after-addons");
    var priceCtrls = $(".price");
    console.log(priceCtrls);
    var Addtotal = 0;
    var totalForAddons = 0;
    priceCtrls.each((idx, ctrl) => {
      var c = $(ctrl);
      var idx = c.attr("data-id");
      if (c.hasClass("quan")) 
        var quan = parseInt(c.val());
      var rate = parseFloat(c.attr("data-rate"));
      //if (isNaN(quan) || (quan === "") || (quan < 0 )) {
      if (isNaN(quan)  || (quan < 0 )) {  
        quan = 1;
        c.val(1);
      }
      console.log("rate--->",rate);
      var total = parseFloat(quan * rate).toFixed(2);
      var outPrice = $("#add-on-out-price-" + idx);
      outPrice.text("$" + total);
      outPrice.attr("data-val", total);
      totalForAddons = parseFloat(total) + parseFloat(totalForAddons);
    });
    var totalBeforeAddons = this.state.TotalBeforeAddons;
    var TotalAfterAddmiles = this.state.TotalAfterAddmiles
    if(parseInt(TotalAfterAddmiles) > 0){
      Addtotal = TotalAfterAddmiles;
    }else{
      Addtotal = totalBeforeAddons;
    }
    var totalAfterAddons = parseFloat(Addtotal) + parseFloat(totalForAddons);
    totalAfterAddons = parseFloat(totalAfterAddons).toFixed(2);
    ctrlAfterAddons.attr("data-val", totalAfterAddons);
    ctrlAfterAddons.text("Total Amount $" + totalAfterAddons);
    this.setState({TotalAfterAddons: totalAfterAddons});
  }
  saveData = (success) =>{
    let rates = reactLocalStorage.getObject("rateData");
    let form = reactLocalStorage.getObject("formData");
    let formData = reactLocalStorage.getObject("form");
    var payment = document.querySelector('input[name="diposit"]:checked');
    if(payment !==null){
      var payableAmount = document.querySelector('input[name="diposit"]:checked').value;
      var paymentType =  document.querySelector('input[name="diposit"]:checked').getAttribute('data-paytype');
    }
    var orderdate =  new Date();
    var addonProducts = [];
    var priceCtrls = $(".price");
    priceCtrls.each((idx, ctrl) => {
      var c = $(ctrl);
      var idx = c.attr("data-id");
      if (c.hasClass("quan")) 
        var quan = parseInt(c.val());
      var rate = parseFloat(c.attr("data-rate"));
       if (isNaN(rate) || (rate === "")) {
        rate = "0";
      }
      var title = c.attr("data-title");
      var total = parseFloat(quan * rate).toFixed(2);
      var obj={
            product :  title,
            rate : rate,
            total : total
      }
      addonProducts.push(obj);
    });
    this.setState({addOnData : addonProducts});
    var totalPrice = this.state.TotalBeforeAddons;
    if(this.state.TotalAfterAddons > 0) 
      totalPrice = this.state.TotalAfterAddons;
    var info = {
      email:form.email,
      pickdate:form.pickDate,
      returndate:form.returnDate,
      includedmiles:rates.miles,
      additionalmiles:this.state.additionalMiles,
      baserate:rates.BaseRate,    
      nights:rates.NumberOfNights,
      firstname:formData.firstName,
      lastname:formData.lastName,
      totalprice:parseInt(totalPrice)+1000,
      addonproducts:JSON.stringify(addonProducts),
      paymenttype:paymentType,
      paymentamount:payableAmount,
      status:"published",
      paymentgatewayresponse:JSON.stringify(success),
      orderdate: moment(orderdate).format('DD-MM-YYYY')
    };
    
    var settings = {
      "async": false,
      "crossDomain": true,
      "url": "https://majestic-be.nextpageit.net/_/items/orders?access_token=IrWq1BZwm3",
      "method": "POST",
      "headers": {
        "content-type": "application/json"
      },
      "processData": false,
      "data": JSON.stringify(info)
    }
    
    $.ajax(settings).done(function (response) {
      Common.ShowPageLoader();
      console.log("save data ===>",response);
    });
  }
  sendEmail = success => {
    let formData = reactLocalStorage.getObject("formData");
    let form = reactLocalStorage.getObject("form");

    reactLocalStorage.set("email", formData.email);
    let emailData = {
      baserate: this.state.rates.BaseRate,
      CostPerNight: this.state.rates.CostPerNight,
      NumberOfNights: this.state.rates.NumberOfNights,
      Total: this.state.pay,
      Type: this.state.rates.Type,
      pickDate: formData.pickDate,
      returnDate: formData.returnDate,
      email: formData.email,
      milesDate: this.state.rates.miles,
      costOfMile: this.state.rates.costOfMiles,
      amount: this.state.pay,
      additionalMiles: this.state.additionalMiles,
      paymentID: success.id,
      addOns: this.state.addOnData,
      afterAddMilesTotal : this.state.TotalAfterAddmiles,
      paymenttype:this.state.paymenttype,
      baseTotal : this.state.TotalBeforeAddons,
      totalAfterAddons : this.state.TotalAfterAddons,
      firstName: form.firstName,
      lastName:form.lastName,
      totalmiles : this.state.TotalMiles,
      path:window.location.origin
    };
    Common.ShowPageLoader();
    var emailAPI = "https://orderformv1.nextpageit.net/send_email_np.php?quote=true&path="+emailData.path+"&totalmiles="+emailData.totalmiles+"&lastName="+emailData.lastName+"&firstName="+emailData.firstName+"&totalAfterAddons="+emailData.totalAfterAddons+"&baseTotal="+emailData.baseTotal+"&paymenttype="+emailData.paymenttype+"&afterAddMiles="+emailData.afterAddMilesTotal+"&currencyCode=" + emailData.currencyCode + "&additionalMiles="+ emailData.additionalMiles+"&amount=" + emailData.amount + "&paymentID=" + emailData.paymentID + "&baseRate= " + emailData.baserate + "&costPerNight= " + emailData.CostPerNight + "&numberOfNights= " + emailData.NumberOfNights + " &Total= " + emailData.Total + " &Type= " + emailData.Type + "&pickDate= " + emailData.pickDate + " &returnDate= " + emailData.returnDate + "&email= " + emailData.email + "&milesDate= " + emailData.milesDate + "&costOfMile=" + emailData.costOfMile + "&addOns=" + JSON.stringify(emailData.addOns) + "";
    //console.log(emailAPI);
    fetch(emailAPI, {method: "post"}).then(function (response) {
    // Common.HidePageLoader();
      document
        .getElementById("thanks")
        .click();
    });
  };
  setAmount = () => {
                          
    // var checkedValues = $("input[name=diposit]:checked")
    var payment = document.querySelector('input[name="diposit"]:checked');

    if (payment !== null) {
      var payableAmount = document
        .querySelector('input[name="diposit"]:checked')
        .value;
      var paymenttype = document.querySelector('input[name="diposit"]:checked').getAttribute("data-paytype");
      this.setState({pay: payableAmount,paymenttype:paymenttype});
      //reactLocalStorage.set("totalPay",payableAmount );
    }
  }
         
close =()=>{       
  Common.HidePageLoader();
}
  render() {
    return (
      <div className="App">
        <div className="instant-quote">
          <div className="checkout">
            <h1>
              <i className="fas fa-cart-arrow-down"/>
              Checkout and Proceed to Payment
            </h1>
           </div>
          <div className="add-on">
            <h2>Optional Add-ons</h2>
          </div>
          <div className="row">
          <div className="optional-add-on">
            <div className="row addOn">
              {(this.state.AddOns.length > 0) ?
              this.state.AddOns
                .map((prod, key) => {
                  return (
                    <div className="col-sm-4 col-xs-12">
                      <div className="addon-img">
                      <input
                          type="checkbox"
                          id={prod.id}
                          data-id={prod.id}
                          className="addonProduct"
                          name="addons"
                          data-name={prod.name}
                          data-price-type={prod.pricetype}
                          rate={prod.price}
                          onClick={this.AddOnSelected}/> <img src={(prod.image !=null) ? prod.image.data.full_url:'images/no-image.png'} alt={prod.name}/>
                      </div>
                      <div className="addon-label">
                      <label>
                        <b>{prod.name}</b>
                      </label>
                      <em>${prod.price}{prod.pricetype == "1" ? "/per night" : ""}</em>
                      </div>
                    </div>
                  )
                }) :""}
            </div>
          </div>
          </div>
          <div className="row">
            <div className="additional-miles">
              <label>Additional Miles:</label>
              <input
                type="number"
                id="miles"
                step="100"
                className="addMiles"
                name="additional-miles"
                onChange={this.AddMiles}
                max="2000"         
                min="100"/>
            </div>
          </div>
          <div className="row">
          <div className="additionsl-miles">
            <div className="miles-ico">
              <i className="fas fa-info-circle"/>
            </div>
            <div className="miles-text">
              <span>Please choose any additional miles you’d like to include. You can also add more miles at the end of your rental period.<em>USD 0.35 per mile</em></span>
              
            </div>
          </div>
          
          <h2 className="checkout-order">
            <i className="fas fa-file-invoice-dollar"/>
            {" "}Order Summary
          </h2>
          </div>
          <div className="order-summary-inner">
            <div className="row">
              <div className="col-sm-3 col-xs-2">
                <h3>Pick Up</h3>
              </div>
              <div className="col-sm-3 col-xs-2">
                <h3>Return</h3>
              </div>
              <div className="col-sm-2 col-xs-2">
                <h3>Nights</h3>
              </div>
              <div className="col-sm-2 col-xs-4 text-center">
                <h3>Included Miles <br/><span className="small-info">(100 * No. Of Nights)</span>
                </h3>
              </div>
              <div className="col-sm-2 col-xs-2 text-right">
                <h3>Per Night</h3>
              </div>
            </div> 
            <div className="row">
              <div className="order-summary-items">
                <div className="col-sm-3 col-xs-2">
                  <h3>{this.state.dates.pickDate}</h3>
                </div>
                <div className="col-sm-3 col-xs-2">
                  <h3>{this.state.dates.returnDate}</h3>
                </div>
                <div className="col-sm-2 col-xs-2">
                  <h3>{this.state.rates.NumberOfNights}</h3>
                </div>
                <div className="col-sm-2 col-xs-4 text-center">
                  <h3>{this.state.rates.miles}</h3>
                </div>
                <div className="col-sm-2 col-xs-2 text-right">
                  <h3 className="total">${this.state.rates.CostPerNight}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="basic-total">
            <div className="col-sm-12 text-right total-price-add-on ">
              <h3 id="total-before-addons" data-val={this.state.TotalBeforeAddons}>Total: ${this.state.TotalBeforeAddons}</h3>
            </div>
          </div>
          <div id="additional-miles">

            {/* <div className="add-on">
              <h2 className="checkout-order">
 
                Additional Miles 
              </h2>
            </div> */}
            <div className="row">
              <div className="optional-add-on add_miles-outer">
                <div className="row addOn add_miles">
                  <div className="col-sm-6 col-xs-6">
                    <label>Additional miles</label>
                  </div>
                  
                  <div className="col-sm-6 col-xs-6 text-right">
                    <label>Price</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div id="new-addon">              
                      <div>
                        <div className="optional-add-on add_miles-outer">
                          <div className="row addOn add_miles">
                            <div className="col-sm-6 col-xs-6">
                              <label id="add-mile">{this.state.additionalMiles}</label>
                            </div>
                            <div className="col-sm-6 col-xs-6 text-right">
                              <label id="add-mile-price">${this.state.addMilesPrice} </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div className="row addmiles" id="add-miles-total">
                      <div className="col-md-12 text-right">
                      <h3 data-val="24324" id="add-miles">Total: ${this.state.TotalAfterAddmiles}</h3>
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div id="addon-data">
            {/* <div className="add-on">
              <h2 className="checkout-order">
                <i className="fas fa-file-invoice-dollar"/>
               {" "} OPTIONAL ADD-ONS
              </h2>
            </div> */}
            <div className="row">
              <div className="optional-add-on products_detail add_miles-outer pr_name_outer">
                <div className="row addOn addOn-products add_miles pr_name">
                  <div className="col-sm-4 col-xs-4">
                    <label>Product Name</label>
                  </div>
                  <div className="col-sm-4 col-xs-4">
                    <label>Quantity</label>
                  </div>
                  <div className="col-sm-4 col-xs-4 text-right">
                    <label>Price</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row products_detail add_miles-outer pr_name_outer">
              <div id="new-addon addOn-products ">
                {this
                  .state
                  .SelectedAddOns
                  .map(function (item, index) {
                    return (
                      <div>
                        <div className="optional-add-on products_detail add_miles-outer pr_name_outer" key={index}>
                          <div className="row addOn addOn-products add_miles pr_name">
                            <div className="col-sm-4 col-xs-4">
                              <h3 >{item.name}</h3>
                            </div>  
                            <div className="col-sm-4 col-xs-4">
                              <input
                                className="price quan"
                                type="number"
                                ref={"refValue" + index}
                                min="1"
                                rate={item.rate}
                                data-rate={item.rate}
                                data-title={item.name}
                                name="quantity"
                                id={"quan-" + item.id}
                                data-id={item.id}
                                onChange={this.AddonQuantityAdded}/>
                            </div>
                            <div className="col-sm-4 col-xs-4 text-right">
                              <label data-rate={item.rate} className="addOnTotal" id={"add-on-out-price-" + item.id}>${item.rate}</label>
                            </div>

                          </div>
                        </div>

                      </div>
                    );
                  }, this)}
                <div className="row" id="addon-total">
                  <div className="col-md-12 text-right">
                    <h3 data-val={this.state.TotalAfterAddons} id="total-after-addons">Total: ${this.state.TotalAfterAddons}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pay-deposit">
            <div className="row">
              <div className="col-sm-4">
                <span id="adv" className="full">
                  <input type="radio" name="diposit" data-paytype="advance" value="1000" onClick={this.setAmount}/>{" "}
                  Pay Deposit - USD $1000{" "}
                </span>
              </div>
              <div className="col-sm-4">
                <span className="full">
                  <input
                    type="radio"
                    name="diposit"
                    data-paytype="full"
                    value={this.state.TotalAfterAddons > 0 ? parseInt(this.state.TotalAfterAddons) +1000 : parseInt(this.state.TotalBeforeAddons) +1000}
                    onClick={this.setAmount}/>
                  Pay Full Amount USD ${parseInt(this.state.TotalAfterAddons) + 1000}
                </span>
              </div>
            </div>
          </div>
          <div className="privacy-policy">
            <input
              type="checkbox"
              name="privacy-policy"
              id="privacy-policy"
              value="privacy-policy"/>{" "}
            I have read and agree to the{" "}
            <a target="_top" href="https://spencermclegg.wixsite.com/majestic/privacy-policy">
              Privacy Policy
            </a> and <a target="_parent" href="https://spencermclegg.wixsite.com/majestic/faq">FAQ</a>
          </div>
          <div className="submit-reservation">
            <button onClick={this.submit}>Submit Reservation ></button>
            <div id="strip">
            {/* test key pk_test_FVpvHaXqC1wdT7NmK6UZWoqj pk_live_lToUFSJfMoAcpyCW4xO5IzQoamount={ this.state.TotalAfterAddons > 0 ? this.state.TotalAfterAddons * 100 : this.state.TotalBeforeAddons * 100}*/}
              <StripeCheckout token={this.onToken} stripeKey="pk_live_lToUFSJfMoAcpyCW4xO5IzQo" amount={ this.state.pay * 100} // cents
                email={this.state.form.email} closed={this.close} currency="USD" name="Majestic RV" description="GET OUT AND EXPLORE" image="images/logo.png" locale="auto"/>
            </div>
            <Link to="/thanks/a" id="thanks" className="leftmenu"/>
          </div>
          <div className="amount-section">
            <span>
              <i className="fas fa-info-circle"/>
              A full refund is available up to 7 days after submitting this reservation. See FAQ for details{" "}
            </span>
          </div>
        </div>
      </div>)}}