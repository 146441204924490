import React, { Component } from 'react';
import * as Common from "../Common";
import {reactLocalStorage} from 'reactjs-localstorage';
import * as $ from 'jquery';
require("../style.css");
var contentOnly = false;
class AddonPop extends Component {
  constructor(props) {
    super(props);
    this.state = {
        addons : this.props.products
    }
  }
  componentDidMount(){
      console.log(this.state.addons,"my modal");
  }
  isLoggeds= ( e)=>{
   // debugger;
//     $('#iframe').attr( 'src','#' );
//     utility.isLog(callback=>{
//     if(callback){
//       console.log("if");
//       var url =e.target.getAttribute("datattr");
//       //document.getElementById.setAttribute(url);
//       $('#iframe').attr( 'src', url );
//       document.getElementById('iframepop').click();
//       // let params = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';
//       // window.open(e.target.getAttribute("datattr"), '_blank', params);
//     }else{
//       document.getElementById('pop').click();
//     }
      
//   });

  }
  
  render() {
    return (
      
    
          <div className="modal fade" id="myModal" role="dialog">
              <div className="modal-dialog">
                  <div className="modal-content pop">
                      <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal">&times;</button>
                          <h4 class="modal-title">Addon Detail</h4>
                      </div>
                      <div className="modal-body error-list text-center">
                      <div id="addon"></div>
                      </div>
                  </div>
              </div>
          </div>
    );
  }
}
AddonPop.defaultProps = {};
export default AddonPop;
