import React, { Component } from 'react';
import * as Common from "../Common";
import * as $ from 'jquery';
import AddonPop from './AddonPop';
import Cookies from 'universal-cookie';
require("../style.css");

class Orders extends Component {
    constructor() {
        super();

        this.state = {
            allorders:[],
            addons :''
        };
    }
 orders = [];
    componentDidMount() {
        const cookies = new Cookies();
        console.log("cookieie",cookies.get('admin'));
       (cookies.get('admin')) ? this.GetOrders() : this.props.history.push("/login");
       
    }
    GetOrders = () => {
        Common.ShowPageLoader();
      const  _this = this;
        Common.getAllOrderData( data => {
            Common.HidePageLoader();
            _this.setState({allorders : data});
        });
    }
    GetBookedDates = ()=>{
        const _this = this;
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": "https://majestic-be.nextpageit.net/_/items/orders?access_token=IrWq1BZwm3",
            "method": "GET",
            "headers": {
              "content-type": "application/json"
            },
            "processData": false,
            "data": ""
          }
          $.ajax(settings).done(function (response) {
                _this.setState({allorders :response.data });
          });

    }
    addonData =(addonData) =>{
        var addons = addonData;
        console.log(addons,"==>addons");
       // this.setState({addons : addons[0].product});
       if(addons.length > 0){
       
       var html =  '<table className="table">';
       html +='<thead><tr>';
       html +='<th>Product Name   </th>';
       html +='<th>  Quantity </th>';
       html +='<th>  Total </th>';
       html += '</tr></thead><tbody>';
       addonData.map(function (order, index) {
           html +='<tr><td>'+order.product+'</td><td>'+ order.total/order.rate +'</td><td>$'+order.total+'</td></tr>';
         }, this);
        html +='</tbody></table>';
        document.getElementById('addon').innerHTML = html;
        document.getElementById('pop').click();
       }else{
        var html = '<h3>No Addon Selected</h3>';   
        document.getElementById('addon').innerHTML = html;
        document.getElementById('pop').click();
       }
    }
    list =()=>{
        const that =this;
        that.state.allorders.map(function (order, index) {
                return <tr>
                <td>{order.name}</td>
                <td>Accountant</td>
                <td>Tokyo</td>
                <td>63</td>
                <td>2011/07/25</td>
                <td>$170,750</td>
                <td>27</td>
                <td>2011/01/25</td>
                <td>{index}</td>
              </tr>
                
              }, that)
  //  }, 3000);
        
        }
        logout =()=>{
            const cookies = new Cookies();
            cookies.set('admin', '');
            this.props.history.push("/login");
        }
    render() {
        return (
            <div className="App">
                <div id="a">
                    <div className="form-instant">
                        <div className="order-inner">
                            <div className="thanks-text">
                                <h3 className="text-center">Orders List</h3>
                                <a href="javascript:;" onClick={e => this.logout()} className="logout text-right">Logout</a>
                                <a href="javascript:;" id="pop" data-toggle="modal" data-target="#myModal"></a>
                                <AddonPop key={1} products={this.state.addons}/>
                                <table id="example" class="table table-striped table-bordered" >
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Pick Date</th>
                <th>Return Date</th>
                <th>Miles</th>
                <th>Addons</th>
                <th>Payment ID</th>
                <th>Order Date</th>
                <th> Total Price</th>
                <th>Advance Payment</th>
            </tr>
        </thead>
        <tbody>
            {
           this.state.allorders.map(function (details, index) {
            return <tr>
            <td>{details.name}</td>
          <td>{(details.email) ? details.email : "-"}</td>
          <td>{(details.pickdate) ? details.pickdate : "-"}</td>
          <td>{(details.returndate) ? details.returndate : "-"}</td>
          <td>{isNaN(details.miles) ? '-' : details.miles}</td>
          <td><a href="javascript:;" onClick={e => this.addonData(details.addons)}>Addons</a></td>
           <td>{details.payment}</td>
           <td>{(details.orderdate) ? details.orderdate : "-"}</td>
          <td>{(details.total) ? "$"+details.total : "-"}</td>
          <td>{(details.paidamount) ? "$"+details.paidamount : "-"}</td>
          </tr>
            
          }, this)

            }
            
        </tbody>
        <tfoot>
            <tr>
            <th>Name</th>
                <th>Email</th>
                <th>Pick Date</th>
                <th>Return Date</th>
                <th>Miles</th>
                <th>Addons</th>
                <th>Payment ID</th>
                <th>Order Date</th>
                <th> Total Price</th>
                <th>Advance Payment</th>
                
            </tr>
        </tfoot>
    </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Orders.defaultProps = {};
export default Orders;
