import React, { Component } from 'react';
import * as Common from "../Common";
import {reactLocalStorage} from 'reactjs-localstorage';
import * as $ from 'jquery';
require("../style.css");

class Thanks extends Component {
    constructor() {
        super();

        this.state = {
        };
    }

    componentDidMount() {
      //  this.email();
      reactLocalStorage.set("home",1);
    }
    email = () => {
        let rateData =  reactLocalStorage.getObject("rateData");
        let formData =  reactLocalStorage.getObject("formData");
        let payment =  reactLocalStorage.getObject("payment");
        reactLocalStorage.set("email",formData.email);
         let emailData = {
             baserate : rateData.BaseRate,
             CostPerNight       : rateData.CostPerNight,
             NumberOfNights		: rateData.NumberOfNights,
             Total		:rateData.Total,
             Type		: rateData.Type,
             startDate		: formData.startDate,
             endDate		: formData.endDate,
             email		: formData.email,
             milesDate		: formData.milesDate,
             costOfMile : rateData.costOfMiles,
             amount : 1000,
             paymentID: payment.paymentID
         }
         var emailAPI = "https://orderformv1.nextpageit.net/send_email_np.php?quote=true&currencyCode="+emailData.currencyCode+"&amount="+emailData.amount+"&paymentID="+emailData.paymentID+"&baseRate= " + emailData.baserate + "&costPerNight= " + emailData.CostPerNight+ "&numberOfNights= "+ emailData.NumberOfNights +  " &Total= " + emailData.Total + " &Type= " + emailData.Type+ "&startDate= "  + emailData.startDate + " &endDate= " + emailData.endDate + "&email= " + emailData.email + "&milesDate= " + emailData.milesDate + "&costOfMile="+ emailData.costOfMile+"";
         console.log(emailAPI);
         fetch(emailAPI,{method:'post'}).then(function (response) {
          
         });
     }
    render() {
        return (
            <div className="App">
             <div id="a">
                <div className="form-instant">
               
                    <div className="form-inner">
                   
                        <div className="thanks-text">
                            <h3>THANKS FOR MAKING THE PAYMENT</h3>
                            <img src="../images/check.png"/>
                            <h4>
                                Please check your email <b>{reactLocalStorage.get('email')}</b>&ensp;for details. Feel free to get in touch with our support 
                                team at <a href="tel:720-775-7694"> 720-775-7694</a> or at<a href="mailto:spencermclegg@gmail.com" target="_top"> spencermclegg@gmail.com</a>
                                 </h4>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Thanks;
