import React, { Component } from 'react';
import * as $ from 'jquery';
import * as Common from "../Common";
import moment from "moment";
import _ from "lodash";
import 'fullcalendar';

import {reactLocalStorage} from 'reactjs-localstorage';
import { datepickerLocale } from 'fullcalendar';
require("../calendar.css");
// var clientId = "687617124661-agp7e25ju7e99ag05hkokch7c4pt22vq.apps.googleusercontent.com"; //choose web app client Id, redirect URI and Javascript origin set to http://localhost
// var apiKey = "AIzaSyBgfsraXCupNPhS3-OaDm56N6_7YxXjTtI"; //choose public apiKey, any IP allowed (leave blank the allowed IP boxes in Google Dev Console)
// //var userEmail = "vikram.nextpage@gmail.com"; //your calendar Id
// //var userEmail="spencermcleggwix@gmail.com";
// var userEmail ="5v1lvo95tokpdtnva0sm4b1aag@group.calendar.google.com";
// var userTimeZone = "America/Denver"; //example "Rome" "Los_Angeles" ecc...
// var scopes = "https://www.googleapis.com/auth/calendar";
var maxRows = 50; //events to shown
let bookedDates = [];
let eventDates = [function (date) {
    return (date.getDay() === 0);

}
];
class Calendar extends Component {
    constructor() {
        super();

        this.state = {
        };
    }
    getbooking(){
        Common.ShowPageLoader();
        const that = this;
        var year = new Date().getFullYear();
        Common.getBookedDates(year, data => {
            console.log("date",data);
         var alldetail;
            data.map((bookDate) => {
             var pickdate =    new Date(bookDate.pickdate); //2019-01-01
             pickdate =   moment(pickdate).format('YYYY-MM-DD');
              var returnDate =  new Date(bookDate.returndate);
              returnDate =   moment(returnDate).format('YYYY-MM-DD 12:30:00');
              console.log("return date==>",moment(returnDate).format('YYYY-MM-DD 12:30:00'));
                alldetail = {
                    title : bookDate.firstname,
                    backgroundColor:"#ccc",
                    borderColor:"#ccc",
                    textColor:"#000",
                    start : pickdate,
                    end : returnDate,
                   // pick : pickdate,
                   // returndate : returnDate,
                    description: 'this is ne descriptuon  '
                    
                }
                bookedDates.push(alldetail);
              });
              Common.HidePageLoader();
              that.getcalendar(bookedDates);
              //that.handleClientLoad();
              //this.getcalendar(bookedDates);
        });
    }
    getcalendar(bookedDates){
        console.log("all events here",bookedDates);
      var bookedData =   _.uniqBy(bookedDates, 'start');
      console.log("filter",bookedData);
        $('#calendar').fullCalendar({
            theme :true,
            themeSystem: 'bootstrap4',
            header: {
              left: 'prev,next today',
              center: 'title',
              right: 'month,basicWeek,basicDay',
              borderColor:"#ccc",
              textColor:"#000",
            },
            eventClick:  function(event, jsEvent, view) {
                $('#modalTitle').html("Booking Detail");
                event.start =   moment(event.start).format('ddd, MMM DD');
                event.end =   moment(event.end).format('ddd, MMM DD');
                $("#name").text(event.title);
                $("#pick").text(event.start);
                $("#return").text(event.end);
                $('#eventUrl').attr('href',event.url);
                document.getElementById('pop')
            .click();
            },
            //defaultDate: '2019-01-12',
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            eventLimit: true, // allow "more" link when too many events
            events: bookedData
            });


        }
    componentDidMount() {
    this.getbooking();
    console.log("all dates==>",this.state.allbookedDates);
    }
    // handleClientLoad = () => {
    //     var that = this;
    //     setTimeout(function () {

    //         window
    //             .gapi
    //             .client
    //             .setApiKey(apiKey);
    //         that.checkAuth();
    //     }, 2000);
    // }
    // checkAuth = () => {
    //     window
    //         .gapi
    //         .auth
    //         .authorize({
    //             client_id: clientId,
    //             scope: scopes,
    //             immediate: true
    //         }, this.handleAuthResult());
    // }
    // handleAuthResult = (authResult) => {
        
    //     this.makeApiCall();
    // }
    // makeApiCall = () => {
    //     const _this = this;
    //     var today = new Date(); //today date
    //     window
    //         .gapi
    //         .client
    //         .load("calendar", "v3", function () {
    //             var request = window
    //                 .gapi
    //                 .client
    //                 .calendar
    //                 .events
    //                 .list({
    //                     calendarId: userEmail,
    //                     timeZone: userTimeZone,
    //                     singleEvents: true,
    //                     timeMin: today.toISOString(), //gathers only events not happened yet
    //                     maxResults: maxRows,
    //                     orderBy: "startTime"
    //                 });
    //             request.execute(function (resp) {
    //                 console.log("Spencer all events===>>", resp);
    //                 var newBookedDates;
    //                 var pickdate;
    //                 var returnDate;
    //                 let alldetail;
    //                 var name;
    //                 resp
    //                     .items
    //                     .map((event, index) => (
    //                     (event.summary === undefined)?
    //                     name = "Unknown" : 
    //                     name = event.summary,
    //                     pickdate =   moment(event.start.date).format('YYYY-MM-DD'),
    //                     returnDate =   moment(event.end.date).format('YYYY-MM-DD 13:30:00'),
    //                     console.log("returnDate",returnDate),
    //                     alldetail = {
    //                         title : name,
    //                         backgroundColor:"#ccc",
    //                         borderColor:"#ccc",
    //                         textColor:"#000",
    //                         start : pickdate,
    //                         end : returnDate,
    //                         description: 'this is ne descriptuon  '
                            
    //                     },
    //                     bookedDates.push(alldetail)
               
    //                         ))
    //                         Common.HidePageLoader();
    //                         _this.getcalendar(bookedDates);
    //             });
    //         });
    // }
    uploaddata =()=> {
      console.log("All ca",bookedDates);
      var info;
      var settings;
      bookedDates.map((event, index) => (
        info = {
            pickdate:event.start,
            returndate:event.end,
            includedmiles:null,
            additionalmiles:null,
            baserate:null,    
            nights:null,
            firstname:event.title,
            lastname:null,
            totalprice:null,
            addonproducts:null,
            paymenttype:null,
            paymentamount:null,
            status:"published",
            paymentgatewayresponse:null,
            orderdate: null
          },
          
          settings = {
            "async": false,
            "crossDomain": true,
            "url": "https://majestic-be.nextpageit.net/_/items/orders?access_token=IrWq1BZwm3",
            "method": "POST",
            "headers": {
              "content-type": "application/json"
            },
            "processData": false,
            "data": JSON.stringify(info)
          },
          $.ajax(settings).done(function (response) {
            Common.ShowPageLoader();
            console.log("save data ===>",response);
          })
      ))

        
    }
    
    render() {
        return (
            <div className="App">
             <div id="a">
                <div className="form-instant calendar-google">
                {/* <a href="javascript:;" onClick={this.uploaddata} >upload data</a> */}
                    <div className="thanks-text">
                        <div id='calendar'></div>
                    </div>
                    {/* <div id="eventContent" title="Event Details" className="hide">
                        Start: <span id="startTime"></span><br />
                        End: <span id="endTime"></span><br /><br />
                        <p id="eventInfo"></p>
                        <p><strong><a id="eventLink" href="" target="_blank">Read More</a></strong></p>
                    </div> */}
                     <a href="javascript:;" id="pop" data-toggle="modal" data-target="#fullCalModal"></a>
                    <div id="fullCalModal" class="modal fade">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span> <span class="sr-only">close</span></button>
                                <h4 id="modalTitle" class="modal-title"></h4>
                            </div>
                            <div id="modalBody" class="modal-body">
                            <div>
                                <div className="modal-fields"><label>Name: </label><span id="name"></span></div>
                                <div className="modal-fields"><label>Picked date: </label><span id="pick"></span></div>
                                <div className="modal-fields"><label>Return date: </label><span id="return"></span></div>

                            </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Calendar;
