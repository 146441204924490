import React, { Component } from 'react';
import * as Common from "../Common";
import $ from "jquery";
import {Link} from "react-router-dom";
import {reactLocalStorage} from 'reactjs-localstorage';
import StripeCheckout from "react-stripe-checkout";
require("../style.css");

class PendingPayment extends Component {
    constructor() {
        super();

        this.state = {
            amount :'0',
            email:'',
            name:''
        };
    }

    checkParams() {
        var amount = parseInt(Common.GetQueryStringValue("amount"));
        var email = Common.GetQueryStringValue("email");
        var name = Common.GetQueryStringValue("name");
        this.setState({amount : amount ,email : email,name:name});
        reactLocalStorage.set('email',email);
        console.log("amount",amount);
        console.log("email",email);
      }
      componentDidMount() {
        $("#strip").hide();
        var url = window.location.origin;
        console.log("URL",url);
        this.checkParams(this.props);
        reactLocalStorage.set("home",1);
      //  this.email();
      reactLocalStorage.set("home",1);
    }
    onToken = token => {
          var info = {
          stripeToken: token.id,
          amount: this.state.amount,
          mode: "sandbox",
          description: "Wix"
        };
    
        var settings = {
          async: false,
          crossDomain: true,
          url: "https://orderformv1.nextpageit.net/Stripe/test.php",
          method: "POST",
          headers: {},
          data: JSON.stringify(info),
          beforeSend:function () {
            Common.ShowPageLoader();
          }
        };
        
          $.ajax(settings).done(function (response) {
            Common.ShowPageLoader();
            console.log(response, "<==respones");
            let success = JSON.parse(response);
            console.log(success, "<==success");
            document
        .getElementById("thanks")
        .click();
          });
      };
      submit(){
        $(".StripeCheckout").click();
        Common.ShowPageLoader();
      }
      close =()=>{       
        Common.HidePageLoader();
      }
    render() {
        return (
            <div className="App">
             <div id="a">
                <div className="payment">
                <h1 className="text-center">Pending Payment </h1>
                <div className="user-detail">
                                <div className="modal-fields"><label>Name: </label><span id="name">{this.state.name}</span></div>
                                <div className="modal-fields"><label>Email: </label><span id="pick">{this.state.email}</span></div>
                                <div className="modal-fields"><label>Pending Amount: </label><span id="return">${this.state.amount}</span></div>

                            </div>
                    <div className="pending-inner">
                    <div className="pending-payment">
                    <button onClick={this.submit}>Pay Amount</button>
                    <div id="strip">
                    {/*amount={ this.state.TotalAfterAddons > 0 ? this.state.TotalAfterAddons * 100 : this.state.TotalBeforeAddons * 100}*/}
                    <StripeCheckout token={this.onToken} stripeKey="pk_test_FVpvHaXqC1wdT7NmK6UZWoqj" amount={ this.state.amount * 100} // cents
                        email={this.state.email} closed={this.close} currency="USD" name="Majestic RV" description="GET OUT AND EXPLORE" image="images/logo.png" locale="auto"/>
                    </div>
                    </div>
                    <Link to="/thanks/a" id="thanks" className="leftmenu"/>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PendingPayment;
