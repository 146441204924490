/*eslint no-undef: 0*/
//import * as $ from 'jquery';
import moment from "moment";
export function ShowLoader(ctrlId) {
    $('#' + ctrlId).loading();
}

export function HideLoader(ctrlId) {
    $('#' + ctrlId).loading('stop');
}

export function ShowPageLoader(){
    $('body').loading();
}

export function HidePageLoader(){
    $('body').loading("stop");
}
export function GetQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }
  
export function getBookedDates(year,callback){

    var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://majestic-be.nextpageit.net/_/items/orders?filter[pickdate][gte]="+year+"-01-01&access_token=IrWq1BZwm3",
        "method": "GET",
        "headers": {
          "content-type": "application/json"
        },
        "processData": false,
        "data": ""
      }
      $.ajax(settings).done(function (response) {
         callback(response.data);
      });
}
export function getOrders(callback){
    var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://majestic-be.nextpageit.net/_/items/orders?access_token=IrWq1BZwm3",
        "method": "GET",
        "headers": {
          "content-type": "application/json"
        },
        "processData": false,
        "data": ""
      }
      $.ajax(settings).done(function (response) {
          callback(response.data);
      });
}
export function getAllOrderData(callback){
    getOrders( data => {
      // var newOrder;
    
       var allOrders = [];
       var newAddon =[];
        data.forEach(order => {
            var newpick = new Date(order.pickdate);
            newpick = moment(newpick).format('DD-MM-YYYY');
            var newreturn = new Date(order.returndate);
            newreturn = moment(newreturn).format('DD-MM-YYYY');
            var addon = JSON.parse(order.addonproducts);
            if(addon == null){
                addon = newAddon;
            }
            var payment = JSON.parse(order.paymentgatewayresponse);
            if(payment == null){
                payment = "-";
            }else{
                payment = payment.id;
            }
            
          var  newOrder ={
            name : order.firstname,
            miles :  parseInt(order.additionalmiles) + 100,
            addons : addon,
            email : order.email,
            orderdate :order.orderdate,
            paidamount : order.paymentamount,
            payment :  payment,
            pickdate :newpick,
            returndate : newreturn,
            total : order.totalprice,
            id : order.id
            }
            allOrders.push(newOrder);
        });
        callback(allOrders);
    });
}