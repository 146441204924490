import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import Booking from "./components/Booking";
import Reserve from "./components/Reserve";
import store from "./store";
import Thanks from './components/Thanks';
import Calendar from './components/Calendar';
import PendingPayment from "./components/PendingPayment";
import Orders from "./components/Orders";
import Login from './components/Login';
ReactDOM.render(
    <Provider store={store}>
    <Router>
      <Switch>
      <Route path='/login' component={Login} />
        <Route path='/orders' component={Orders} />
      <Route path='/payment' component={PendingPayment}/>
      <Route path='/calendar' component={Calendar}/>
      <Route path='/reserve' component={Reserve}/>
        <Route path='/thanks' component={Thanks}/>
        <Route path='/' component={Booking}/>
      </Switch>
    </Router>
    </Provider>, document.getElementById("root"),);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
