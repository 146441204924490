import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import moment from "moment";
import $ from "jquery";
import * as Common from "../Common";

import {reactLocalStorage} from 'reactjs-localstorage';
// var clientId = "687617124661-agp7e25ju7e99ag05hkokch7c4pt22vq.apps.googleusercontent.com"; //choose web app client Id, redirect URI and Javascript origin set to http://localhost
// var apiKey = "AIzaSyBgfsraXCupNPhS3-OaDm56N6_7YxXjTtI"; //choose public apiKey, any IP allowed (leave blank the allowed IP boxes in Google Dev Console)
// //var userEmail = "vikram.nextpage@gmail.com"; //your calendar Id
// var userEmail="5v1lvo95tokpdtnva0sm4b1aag@group.calendar.google.com";
var userTimeZone = "America/Denver"; //example "Rome" "Los_Angeles" ecc...
var maxRows = 10; //events to shown
let eventDates = [function (date) {
        return (date.getDay() === 0);

    }
];
// let env = 'sandbox'; // you can set here to 'production' for production
// let currency = 'USD'; // or you can set this value from your props or state
// let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
// const client = {
//     sandbox: 'AcW5nsruGl7MtwZaUct_V-YNKBp-jtN9Z2hYZez26lXyJ2e5b65Mb3ovV2DcW1GTIUbFlAxV8mppT5QM',
//     production: 'YOUR-PRODUCTION-APP-ID'
// }
let tempData = {};
var scopes = "https://www.googleapis.com/auth/calendar";

export default class Booking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailClass: 'form-control',
            firstNameClass: 'form-control',
            lastNameClass: 'form-control',
            mileClass: 'form-control',
            startClass: 'flatpickr-input',
            endClass: 'flatpickr-input',
            date: new Date(),
            minStart: new Date(),
            hide: "hide",
            calendarView: true,
            disablesDates: [],
            billingAgreementId: '',
            rates: [],
            disabled: 'disabled',
            returnDate: '',
            pickDate: ''
        };
    }
    componentDidMount() {
       
        $('#summary').hide();
        if ($(window).height() < 767) {
            $("#comp-jr7qz47j").css({"height": "600px !important"});
        }
        this.GetBookedDates();
        document
            .getElementById("returnDate")
            .disabled = true;
            
    }
    submit = () => {
        const _this = this;
        Common.ShowPageLoader();
        var email = document
            .getElementById("email")
            .value;
        var pickDate = document
            .getElementById("pickDate")
            .value;
        var returnDate = document
            .getElementById("returnDate")
            .value;
        var firstName = document.getElementById("first-name").value;
        var lastName  = document.getElementById("last-name").value;    
        let form = {
            pickDate: pickDate,
            returnDate: returnDate,
            firstName:firstName,
            lastName : lastName,
            email: email,
            miles: 100
        }
        reactLocalStorage.setObject("form", form);
        _this.setState({pickDate: pickDate});
        _this.setState({returnDate: returnDate});
        var letters = /^[A-Za-z]+$/;
        console.log("true false",firstName.match(letters));
        if (firstName.trim() == '' || firstName.match(letters) == null ) {
            _this.setState({firstNameClass: "form-control red"});
            Common.HidePageLoader();
            return false;
            
        }else{
            _this.setState({firstNameClass: "form-control"});
        }
        if (lastName.trim() =='' || lastName.match(letters) == null ) {

            _this.setState({lastNameClass: "form-control red"});
            Common.HidePageLoader();
            return false;
            
        }else{
            _this.setState({lastNameClass: "form-control"});
        }
        if (this.validateEmail(email)) {
            _this.setState({emailClass: "form-control"})
        } else {
            _this.setState({emailClass: "form-control red"});
            Common.HidePageLoader();
            return false;
        }

        if (pickDate === '') {
            _this.setState({startClass: "flatpickr-input red"});
            Common.HidePageLoader();
            return false;
        } else {
            _this.setState({startClass: "flatpickr-input"});
        }
        if (returnDate === '') {
            _this.setState({endClass: "flatpickr-input red"});
            Common.HidePageLoader();
            return false;
        } else {
            _this.setState({endClass: "flatpickr-input"});

        }
        let body = {
            pickDate: _this.convert(pickDate),
            returnDate: _this.convert(returnDate),
            email: email,
            miles: 100
        }
        reactLocalStorage.setObject("formData", body);
        _this.getRates(body);
    }

    convert = (str) => {
        var date = new Date(str),

            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [
            this
                .state
                .date
                .getFullYear(),
            mnth,
            day
        ].join("-");
    }
    emailValidation = () => {
        const _this = this;
        var email = document
            .getElementById("email")
            .value
        console.log("email-->", document.getElementById("email").value);
        this.validateEmail(email)
            ? _this.setState({emailClass: "form-control"})
            : _this.setState({emailClass: "form-control red"})
    }
    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    handleChange = () => {
        const _this = this;
        var pickDate = document
            .getElementById("pickDate")
            .value;
        if (pickDate !== '') {

            pickDate = new Date(this.convert(pickDate));
            var day = pickDate.getDay();
            var new_date;

            console.log("days--->", day);
            if (day >= 4) {
                new_date = pickDate.setDate(pickDate.getDate() + 4);

                _this.setState({minStart: new_date});
                _this.setState({
                    calendarView: !this.state.calendarView
                });
            } else {
                new_date = pickDate.setDate(pickDate.getDate() + 3);
                _this.setState({minStart: new_date});
                _this.setState({
                    calendarView: !this.state.calendarView
                });
            }
            document
                .getElementById("returnDate")
                .disabled = false;
        }
    }
    getRates = (body) => {
        const _this = this;
        var API = "https://orderformv1.nextpageit.net/get-rates.php?quote=true&pickDate= " + body['pickDate'] + "&returnDate=" + body['returnDate'] + "&miles=" + body['miles'] + "";
        fetch(API, {method: "get"}).then((httpResponse) => {

            if (httpResponse.ok) {
                return httpResponse.json();
            }

        }).then((json) => {
            console.log("recevied data", json);
            if (json.status !== "") {;
                _this.errorMail(body);
                return false;
            } else {
                console.log("json==>", json);
                $('#checkPrice').hide();
                $('#summary').show();
                document
            .getElementById("returnDate")
            .disabled = true;
            document
            .getElementById("pickDate")
            .disabled = true;
            document
            .getElementById("last-name")
            .disabled = true;
            document
            .getElementById("first-name")
            .disabled = true;
            document
            .getElementById("email")
            .disabled = true;
                Common.HidePageLoader();
                tempData = json;
                reactLocalStorage.set("home",0);
                reactLocalStorage.setObject("rateData", json);
                _this.setState({rates: json});
            }
        }).catch((err) => {
            console.log("error ----->>>>>>>>>", err);
        });
    }
    errorMail = (errorData) => {
        Common.HidePageLoader();
         document
            .getElementById('pop')
            .click();
        var errorStartDate = errorData.startDate;
        var errorEndDate = errorData.endDate;
        var errorEmail = errorData.email;
        var errorEmailAPI = "https://utils.nextpageit.net/spencer/send_email_np.php?error= true&errorStartDat" +
                "e= " + errorStartDate + "&errorEndDate= " + errorEndDate + " &errorEmail= " + errorEmail + "";
        fetch(errorEmailAPI, {method: 'get'}).then(function (response) {
           
            //return response.json();
        });
    }
    GetBookedDates = ()=>{
        const _this = this;
        var dateNew = new Date();
        var neweventDates =[];
        // add a day
        dateNew.setDate(dateNew.getDate() + 1);
        eventDates.push(moment(this.state.date).format('ddd, MMM DD'));
        eventDates.push(moment(dateNew).format('ddd, MMM DD'));
        console.log("dates==>",eventDates);
        var settings = {
            "async": true,
            "crossDomain": true,
            "url": "https://majestic-be.nextpageit.net/_/items/orders?access_token=IrWq1BZwm3",
            "method": "GET",
            "headers": {
              "content-type": "application/json"
            },
            "processData": false,
            "data": ""
          }
          

          $.ajax(settings).done(function (response) {
            var bookedDates ;
            if(response.data.length > 0)
                response.data.map((detail)=>{
                    console.log("get date==>",detail.pickdate);
                    var startDate = new Date(detail.pickdate);
                    var endDate = new Date(detail.returndate);
                   
                    bookedDates = {
                        from: moment(startDate).format('ddd, MMM DD'),
                        to: moment(endDate).format('ddd, MMM DD')
                     }
                     eventDates.push(bookedDates);
                });
             console.log("booking date get ===>",eventDates);   
            var currentDate =  new Date();
             _this.setState({disablesDates: eventDates});
          });

    }
    render() {   
        return (
            <div className="App">
                <div className="instant-quote" >
                    <h1>
                        Instant Quote</h1>
                       
                    <div className="quote-form">
                        <input
                            type="text"
                            id="first-name"
                            className={this.state.firstNameClass}
                            placeholder="First name"
                            pattern="[A-Za-z]+"
                            />
                            <input
                            type="text"
                            id="last-name"
                            className={this.state.lastNameClass}
                            placeholder="Last name"
                            pattern="[A-Za-z]+"
                            />
                            <input
                            type="text"
                            id="email"
                            onChange={this.emailValidation}
                            className={this.state.emailClass}
                            placeholder="Email"
                            
                            />
                        <div className="start-date-block date-block">
                        
                        <Flatpickr
                            className={this.state.startClass}
                            id="pickDate"
                            placeholder="Select pick date"
                            options={{
                            disableMobile: true,
                            disable: this.state.disablesDates,
                            minDate: new Date(),
                            dateFormat: "D, M d"
                        }}
                            onChange={this.handleChange}/>
                        <span className="end-date">
                            <i className="far fa-calendar-alt"></i>
                        </span>
                        </div>
                        <div className="end-date-block date-block">
                        <Flatpickr
                            className={this.state.endClass}
                            id="returnDate"
                            placeholder="Select return date"
                            options={{
                            disableMobile: true,
                            noCalendar: false,
                            disable: this.state.disablesDates,
                            minDate: this.state.minStart,
                            dateFormat: "D, M d"
                        }}/>

                        <span className="end-date second">
                            <i className="far fa-calendar-alt"></i>
                        </span>
</div>
                        <a href="javascript:;" id="pop" data-toggle="modal" data-target="#myModal"></a>
                        <div className="modal fade" id="myModal" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content pop">
                                    <div className="modal-header after-select">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body error-list">
                                        <div className="row">
                                            <p className="error-title">Oops!</p>
                                        </div>
                                        <p className="error-line">Something went wrong. Please contact us at
                                            <a href="tel:720-775-7694">  
                                            &nbsp; 720-775-7694</a>
                                        </p>
                                        <p className="error-line">
                                            so we can get you a quote for your dates.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button id="checkPrice" onClick={this.submit}>
                            Check Pricing</button>
                    </div>
                    {/* <div id="temp-data">
                        <span>Base Rate :
                            <b>{tempData.BaseRate}</b>,</span>
                        <span>
                            No of Nights :
                            <b>{tempData.NumberOfNights}</b>,
                        </span>
                        <span>
                            Final Cost:
                            <b>{tempData.Total}</b>,</span>
                        <span>
                            Miles :
                            <b>{tempData.miles}</b>,</span>
                        <span>
                            Cost Per Night :
                            <b>{tempData.CostPerNight}</b>,</span>
                        <span>
                            Cost Of Miles :
                            <b>{tempData.costOfMiles}</b>
                        </span>
                    </div> */}
                    <div id="summary" className="order-summary">
                        <h2>
                            <i className="fas fa-file-invoice-dollar"></i>
                           {" "} Order Summary</h2>
                        <div className="order-summary-inner">
                            <div className="row">
                                <div className="col-sm-3 col-xs-2">
                                    <h3>Pick Up</h3>
                                </div>
                                <div className="col-sm-3 col-xs-2">
                                    <h3>Return</h3>
                                </div>
                                <div className="col-sm-2 col-xs-2">
                                    <h3>Nights</h3>
                                </div>
                                <div className="col-sm-2 col-xs-4 text-center">
                                    <h3>Included Miles
                                    </h3>
                                </div>
                                <div className="col-sm-2 col-xs-2 text-right">
                                    <h3>Per Night</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="order-summary-items">
                                    <div className="col-sm-3 col-xs-2">
                                        <h3>{this.state.pickDate}</h3>
                                    </div>
                                    <div className="col-sm-3 col-xs-2">
                                        <h3>{this.state.returnDate}</h3>
                                    </div>
                                    <div className="col-sm-2 col-xs-2">
                                        <h3>{this.state.rates.NumberOfNights}</h3>
                                    </div>
                                    <div className="col-sm-2 col-xs-4 text-center">
                                        <h3>{this.state.rates.miles}</h3>
                                    </div>
                                    <div className="col-sm-2 col-xs-2 text-right">
                                        <h3 className="total">${this.state.rates.CostPerNight}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="grand-total">
                                <div className="row">
                                    <div className="col-sm-12 text-right">
                                        <h3>Total ${this.state.rates.Total}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="reserve-dates">
                                <Link to="/reserve">RESERVE YOUR DATES NOW ></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
)}}